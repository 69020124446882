<template>
  <div v-if="data">

    <div class="flix-container-fluid flix-html-html" style="padding: 50px 0; background-color:#717C80; color: #fff;">
      <h1 class="flix-text-center flix-html-h1"><flixIcon :id="'calendar'" /> {{ $tc('message.pageBuilder', 1) }}</h1>
      <div class="flix-text-center pb_txt">{{ data.data.title }}</div>
    </div>
    <div class="flix-container">
      <div class="flix-form-group" :key="updateKey">
        <assistentPreview :settings="{noTitle: true}" :data="data.data" :events="[]"/>
      </div>
    </div>
    <infobox v-if="isAdmin && data" :data="data"/>
  </div>
</template>
<script>
export default {
  components: {
    assistentPreview () { return import('@/components/assistent/components/assets/preview') },
    infobox () { return import('@/components/privateEvent/infobox.vue') }
  },
  props: {},
  data () {
    return {
      data: false,
      downloadPDF: false,
      isAdmin: false,
      updateKey: new Date().getTime()
    }
  },
  methods: {
    setDownload () {
      this.downloadPDF = true
      setTimeout(function () {
        this.downloadPDF = false
      }.bind(this), 1000)
    },
    getData (callback) {
      this.$store.dispatch('getPrivateAssistent', { id: this.$route.params.id, callback: function (data) { callback(data) } })
    }
  },
  mounted () {
    this.getData(function (data) { this.data = data }.bind(this))
    if (this.$store.getters.myPrivateCalendars.indexOf(this.$route.params.id) !== -1) {
      this.isAdmin = true
    }
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-container
    margin-top: 70px
    margin-bottom: 50px
</style>
